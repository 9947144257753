<template>
  <div class="page_bg">
    <div class="invite_bg" style="background:#1757AF">
      <!-- <van-image width="4.35rem" height="0.68rem" style="margin-top:0.43rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/invite_logo.png"
      /> -->
      <van-image width="9.9rem" height="6.4rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/invite_title_02.png"
      />
      <div class="invite_my ">
        <!-- 营业执照 -->
        <div class="certificationReg_img_box">
          <div class="box_top flex-r-c-c">
            <div class="img_name">营业执照认证</div>
          </div>
          <van-uploader v-model="fileList01" :after-read="afterRead01" :max-count="1">
            <van-image v-if="fileList01.length===0" width="6.29rem" height="3.68rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification01.png"
            />
          </van-uploader>
          <div v-if="fileList01.length === 0" class="up_tip">点击上传营业执照</div>
        </div>
        <div class="certificationReg_img_box" style="margin-top: 0.3rem;height: auto;">
          <van-form style="padding-top: 0.3rem;">
            <van-field v-model="regStepFrom.companyName" label="公司名称" name="companyName" placeholder="请输入公司名称" />
            <van-field v-model="regStepFrom.businessLicense" label="三证合一码" name="businessLicense"
                       placeholder="请输入三证合一码"
            />
            <div class="login_sub" style="margin-left: 0.2rem;">
              <van-image width="7.63rem" height="1.81rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/invite3_btn_01.png"
                         @click="onSubmit"
              />
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
  </div>
</template>

<script>

import { upProToOss } from '@/utils/ali-oss'
import { common_ocr } from '@/api/public'
import { enterpriseInformation } from '@/api/user'
export default {
  name: 'InviteReg',
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      inviteCode: '',
      certificationShow: true,
      loading: false,
      fileList01: [],
      regStepFrom: {}
    }
  },
  created() {
    this.inviteCode = this.$route.query.code
    this.loadDistrict()
  },
  methods: {
    // 进入第一步
    backStep() { this.$emit('nextStep', '3') },

    // OCR识别
    OCR(url) {
      this.$toast.loading({ message: 'OCR识别中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      common_ocr(url).then(res => {
        var vetLicense = this.regStepFrom.vetLicense || ''
        this.$set(res.data, 'vetLicense', vetLicense)
        if (res.data) { this.regStepFrom = res.data }
        this.$toast.clear()
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 图片上传
    afterRead01(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      upProToOss(file).then(res => {
        this.fileList01 = []
        this.fileList01.push({ url: res, isImage: true })
        this.$set(this.regStepFrom, 'ocrUrl', res || '')
        this.OCR(res)
      })
    },
    // 点击
    onSubmit(values) {
      this.loading = true
      this.$set(this.regStepFrom, 'inviteMobile', this.$route.query.mobile)
      this.$set(this.regStepFrom, 'inviteLink', this.inviteCode)
      console.log(this.regStepFrom)
      enterpriseInformation(this.regStepFrom).then(res => {
        // this.$toast({ message: '已提交审核！', duration: 3 * 1000 })
        this.$router.push('/user/inviteReg2?code=' + this.inviteCode + '&ocrSignId=' + res.data.ocrSignId +
          '&ocrUrl=' + res.data.ocrUrl + '&businessLicense=' + res.data.businessLicense +
          '&companyName=' + res.data.companyName + '&legalPerson=' + res.data.legalPerson)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.certificationReg_img_box {
  // height: 6.35rem;
  background: #ffffff;
  border-radius: 0.4rem;
  opacity: 1;
  .box_top {
    padding-top: 0.43rem;
    margin-bottom: 0.43rem;
    .img_name {
      font-weight: 500;
      font-size: 0.43rem;
      line-height: 0.96rem;
      color: #333;
    }
    .img_expale {
      font-size: 0.32rem;
      color: #0767ab;
    }
  }
  .up_tip {
    font-size: 0.37rem;
    line-height: 0.96rem;
    color: #999999;
  }
  .van-uploader__preview-image {
    width: 6.29rem;
    height: 3.68rem;
  }
  .van-uploader__preview-delete {
    width: 0.5rem;
    height: 0.5rem;
    .van-uploader__preview-delete-icon {
      font-size: 0.5rem;
    }
  }
  .login_sub {
    margin-top: 2rem;
    padding-bottom: 0.5rem;
  }
}
.invite_bg {
  height: 22rem;
}
.invite_my {
  width: 9.2rem;
  margin-left: 0.4rem;
  margin-top: -0.43rem;
  background: #ffffff;
  border-radius: 0.4rem;
}
</style>
